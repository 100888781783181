:root {
    --primary-dark: #1a3c61;
    --primary-light: #55CCEE;
    --secondary: #0e8c3d;
    --background: #f0f4f8;
    --text-dark: #333;
    --text-light: #fff;
    --accent: #ff9800;
  }
  
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: var(--text-dark);
    background-color: var(--background);
    margin: 0;
    padding: 0;
  }
  
  .privacy-policy-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    background-color: var(--primary-dark);
    padding: 1rem 0;
    color: var(--text-light);
  }
  
  .header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-light);
  }
  
  .nav-menu {
    display: flex;
    gap: 2rem;
  }
  
  .nav-link {
    color: var(--text-light);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: var(--primary-light);
  }
  
  .main-content {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    width: 100%;
  }
  
  .page-title {
    text-align: center;
    color: var(--secondary);
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .policy-container {
    background-color: var(--text-light);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 0 auto;
    max-width: 800px;
  }
  
  .accordion-section {
    margin-bottom: 1rem;
  }
  
  .accordion-button {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: left;
    transition: all 0.3s ease;
    color: var(--primary-dark);
  }
  
  .accordion-button:hover {
    background-color: var(--primary-light);
    color: var(--text-light);
  }
  
  .accordion-button.active {
    background-color: var(--primary-dark);
    color: var(--text-light);
  }
  
  .arrow {
    font-size: 0.8rem;
    transition: transform 0.3s ease;
  }
  
  .arrow.active {
    transform: rotate(180deg);
  }
  
  .accordion-content {
    padding: 1rem;
    background-color: var(--text-light);
    border-radius: 0 0 4px 4px;
    line-height: 1.6;
    color: var(--text-dark);
    border-left: 3px solid var(--primary-light);
    margin-top: 0.5rem;
  }
  
  .footer {
    background-color: var(--primary-dark);
    color: var(--text-light);
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }
  
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
    }
    
    .policy-container {
      padding: 1rem;
    }
    
    .page-title {
      font-size: 2rem;
    }
    
    .accordion-button {
      padding: 0.8rem;
      font-size: 1rem;
    }
  }